import React from "react";

const Image = (props) => {
  return (
    <img
      width="300"
      src={props.src}
      alt={props.alt}
    />
  );
}


export default Image;