import React, { useEffect } from "react";
import MyButton from "../components/button";
import Layout from "../components/layout";
import Title from "../components/title";
import Card from "../components/card";
import Image from "../components/image";
import Description from "../components/description";
import { useSelector, useDispatch } from "react-redux";
import { setData, setNextData, setIsLoading } from "../state/questionsSlice";
import data from "../data/remote";
import Loading from "../components/loading";
import background from "../assets/images/backpat.png";

const Questions = () => {
  const currentQuestion = useSelector((state) => state.questions.currentData);
  const isLoading = useSelector((state) => state.questions.isLoading);

  const dispatch = useDispatch();

  useEffect(() => {

    if (isLoading) {
      data.then((res) => {
        dispatch(setData(res));
        dispatch(setIsLoading(false));
      });
    }
  }, []);

  if (currentQuestion != null) {
    return (
      <Layout pageTitle={currentQuestion.title}>
        <Loading isLoading={isLoading} />
        <div style={styles.body}>
          <Card>
            <Image
              src={`https://secure.vertex-colon.ca/${currentQuestion.image}`}
              alt={currentQuestion.title}
            />
            <Title>{currentQuestion.title}</Title>

            <Description>{currentQuestion.description}</Description>
            {currentQuestion.symptoms_answers.map((answer) => {
              return (
                <MyButton
                  key={answer.id}
                  onClick={() => {
                    dispatch(setIsLoading(true));
                    dispatch(setNextData(answer));
                    setTimeout(() => {
                      dispatch(setIsLoading(false));
                    }, 1000);
                  }}
                >
                  {answer.answer}
                </MyButton>
              );
            })}
          </Card>
        </div>
      </Layout>
    );
  } else return <Loading isLoading={true} />;
};

const styles = {
  body: {
    // background: 'linear-gradient(180deg, #FAE0DE 0%, #FAC2BE 100%)',
    backgroundImage: `url(${background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    minHeight: "100vh",
    display: "flex",
    padding: "15px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default Questions;
