import React from "react";
import Layout from "./layout";
import CircularProgress from "@mui/material/CircularProgress";

export default function Loading({ isLoading }) {
  if (isLoading) {
    return (
      <Layout pageTitle="جاري التحميل">
        <div style={styles.body}>
          <CircularProgress />
        </div>
      </Layout>
    );
  } else return null;
}

const styles = {
  body: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100%",
    background: "rgba(255, 255, 255, 0.6)",
    backdropFilter: "blur(34px)",
    WebkitBackdropFilter: "blur(10px)",
    position: "fixed",
    top: "0px",
    zIndex: 100,
  },
};
