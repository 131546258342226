import React from 'react'

const Description = (props) => {
    return (
        <p style={descriptionStyle}>
            {props.children}
        </p>
    )
}


const descriptionStyle = {
    fontFamily: 'bahij',
    fontSize: "20px",
}

export default Description;
