import axios from "axios";

var config = {
  method: "get",
  url: "https://secure.vertex-colon.ca/api/questions",
  headers: {},
};

const data = axios(config)
.then(function (response) {
  return response.data.data[0];
})
.catch(function (error) {
  console.log(error);
});

export default data;
