import React from 'react'

 const Title = (props) => {
    return (
        <div style={titleStyle}>
            {props.children}
        </div>
    )
}

const titleStyle = {
    fontFamily: 'bahij',
    marginTop: '10px',
    marginBottom: '10px',
    fontSize: '22px'
}

export default Title;